<script setup lang="ts">
import type { DBImage } from '@tn/shared';
import type { PropType } from 'vue';
import { useCartStore } from '~/stores/cartStore';
import EVENTS from '~/constants/events';
import { getImageObject } from '~/helpers/images';

const props = defineProps({
  title: {
    type: String,
    required: true,
  },

  size: {
    type: String,
    required: false,
    default: null,
  },

  image: {
    type: Object as PropType<DBImage>,
    required: false,
    default: null,
  },

  productId: {
    type: String,
    required: true,
  },

  brandName: {
    type: String,
    required: false,
    default: null,
  },

  brandId: {
    type: String,
    required: false,
    default: null,
  },

  readOnly: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const cartStore = useCartStore();
const { $trackEvent } = useNuxtApp();
const cartItemsCount = computed(() => cartStore.cartItemsCount);

const productImage = computed(() =>
  getImageObject(props.image, {
    alt: `${props.image?.name} image`,
  })
);

const removeCartItem = () => {
  $trackEvent(EVENTS.REMOVE_PRODUCT_CLICKED, {
    cart_product_count_before_click: cartItemsCount.value,
    brand_name: props.brandName,
    brand_id: props.brandId,
    product_name: props.title,
    product_id: props.productId,
  });
  cartStore.removeProductFromCart(props.productId);
};
</script>

<template>
  <div class="flex flex-row items-start justify-between pb-4 pt-4">
    <div class="flex flex-row">
      <div class="mr-6 h-14 w-14 rounded-lg border border-b-2 border-black">
        <TnImage
          :image="productImage"
          width="55"
          height="55"
          sizes="55px xs:110px"
          class="rounded-lg"
        />
      </div>
      <div class="max-w-52">
        <span class="text-sm opacity-80"> {{ title }}</span>
        <div class="mt-2 flex flex-row items-center">
          <div
            v-if="size"
            class="border-tnNeutral-400 mr-4 max-w-32 overflow-hidden rounded-md border px-3"
          >
            <div class="text-tnNeutral-400 overflow-hidden truncate text-xs">
              {{ size }}
            </div>
          </div>
          <div
            v-if="!readOnly"
            class="flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border border-b-2 border-black"
            @click="removeCartItem"
          >
            <UIcon name="i-heroicons-trash-solid" />
          </div>
        </div>
      </div>
    </div>
    <TnTokenIcon size="xs" class="ml-2"> 1 </TnTokenIcon>
  </div>
</template>
