<script setup lang="ts">
import type { CartItem } from '@tn/shared';
import { useTokensStore } from '~/stores/tokensStore';

const props = defineProps({
  readOnly: {
    type: Boolean,
    required: false,
    default: false,
  },

  // override with read-only items
  items: {
    type: Array as PropType<CartItem[]>,
    required: false,
    default: null,
  },
});

const tokenStore = useTokensStore();
const tokensCount = computed(() => tokenStore.validTokens.length);
const cartStore = useCartStore();
const cartItems = computed(() => props.items || cartStore.cart.products);

const cartIsEmpty = computed(() => !cartItems.value.length);

const infoMessage = computed((): string => {
  if (cartStore.cart.products.length === tokensCount.value) {
    return 'You have reached the maximum of samples';
  }

  if (cartStore.cart.products.length > tokensCount.value) {
    return 'You have exceeded your token balance';
  }

  return `You can only order a maximum of ${tokensCount.value} samples`;
});

const infoTextColor = computed(() => {
  return cartStore.cart.products.length >= tokensCount.value;
});

const headerIcon = computed(() => {
  if (cartStore.cart.products.length < tokensCount.value) {
    return 'i-heroicons-information-circle-solid';
  }

  if (cartStore.cart.products.length === tokensCount.value) {
    return 'i-heroicons-check';
  }

  return null;
});
</script>

<template>
  <div class="w-full rounded-xl border border-b-4 border-black bg-white">
    <header v-if="!readOnly">
      <div
        class="flex items-center rounded-t-xl px-4 py-2"
        :class="{
          'bg-blue-50': cartStore.cart.products.length < tokensCount,
          'bg-green-500': cartStore.cart.products.length === tokensCount,
          'bg-red-400': cartStore.cart.products.length > tokensCount,
          'text-white': infoTextColor,
        }"
      >
        <UIcon
          v-if="headerIcon"
          :name="headerIcon"
          class="mr-2 h-4 w-4"
          :class="{
            'text-blue-500': cartStore.cart.products.length < tokensCount,
            'text-white': cartStore.cart.products.length >= tokensCount,
          }"
        />
        <span class="text-xs">
          {{ infoMessage }}
        </span>
      </div>
    </header>
    <CartEmpty v-if="cartIsEmpty" />
    <div v-if="!cartIsEmpty" class="px-4">
      <div v-for="(item, index) in cartItems" :key="item.title">
        <CartItem
          :title="item.title"
          :image="item.image"
          :size="item.size"
          :product-id="item.productId"
          :brand-name="item.brandName"
          :brand-id="item.brandId"
          :read-only="readOnly"
        />
        <UDivider v-if="index !== cartItems.length - 1" />
      </div>
    </div>
    <footer v-if="!readOnly">
      <div class="bg-beige-600 flex justify-between rounded-b-lg px-4 py-2">
        <span>Tokens Available</span>
        <TnTokenIcon size="sm">
          {{ tokensCount }}
        </TnTokenIcon>
      </div>
    </footer>
  </div>
</template>
