<script setup lang="ts">
import EVENTS, { SECTIONS } from '~/constants/events';

const modal = useModal();

const { $trackEvent } = useNuxtApp();
const route = useRoute();

const howItWorksClicked = () => {
  if (modal.isOpen.value) {
    modal.close();
  }

  $trackEvent(EVENTS.LEARN_HOW_TOKENS_WORK_CLICKED, {
    page: route.meta.eventPageName,
    section: SECTIONS.CART,
  });
  navigateTo({ name: 'wallet', query: { openHowTokenWorksModal: 'true' } });
};
</script>

<template>
  <div class="flex flex-col items-center pb-5">
    <span class="pb-4 pt-6 text-center text-sm opacity-80"
      >Use tokens to order samples!</span
    >
    <div class="mb-5 flex flex-row items-center">
      <div class="rounded-lg border border-black p-4">
        <TnTokenIcon size="lg" />
      </div>
      <div>
        <img src="@/assets/arrow-green.svg" alt="Arrow" class="px-3" />
      </div>
      <div
        class="flex h-20 w-20 items-center justify-center rounded-lg border border-black"
      >
        <img src="@/assets/snack.svg" alt="Snack" />
      </div>
    </div>
    <UButton
      color="secondary"
      variant="solid"
      size="xs"
      class="py-2 font-semibold"
      @click="howItWorksClicked"
    >
      Learn how tokens work
    </UButton>
  </div>
</template>
